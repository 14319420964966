import React, { Component } from 'react';
import getBrowserFingerprint from 'get-browser-fingerprint';
import ssdeep from 'ssdeep.js';
import Axios from 'axios'
import {v4 as uuidv4} from 'uuid'
import axios from 'axios';
import parser from 'ua-parser-js'

class LisaCollector extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    async getIPs() {
        var IPv4;
        try {
            IPv4 = (await Axios.get("https://api.ipify.org?format=json")).data.ip
        } catch {
            IPv6 = null;
        }
        var IPv6;
        try {
            IPv6 =  (await Axios.get("https://api6.ipify.org?format=json")).data.ip
        } catch {
            IPv6 = null;
        }
        return {
            IPv4, IPv6
        }
    }
    leaveCookie() {
        var cookie;
        if(!window.localStorage.getItem("lisa")) {
            //No cookie
            cookie = uuidv4();
            window.localStorage.setItem("lisa", cookie);
        } else {
            cookie = window.localStorage.getItem("lisa");
        }
        return cookie;
    }
    async componentDidMount() {
        var IPs = await this.getIPs();
        var cookie = this.leaveCookie();
        
        const fingerprint = getBrowserFingerprint(true);
        console.log(fingerprint);
        var eicarstring = ssdeep.digest("X5O!P%@AP[4\\PZX54(P^)7CC)7}$EICAR-STANDARD-ANTIVIRUS-TEST-FILE!$H+H*");
        console.log("ssdeep1: ", eicarstring);

        var eicarstring2 = ssdeep.digest("STANDARD-ANTIVIRUS-TEST-FILEfdgahsdfasadsudfhaisudhf*");
        console.log("ssdeep2: ", eicarstring2);

        console.log("Similarity:", ssdeep.similarity(eicarstring, eicarstring2));
        const { userAgent, language, languages, platform, hardwareConcurrency, deviceMemory } = window.navigator;
        const plugins = Object.entries(window.navigator.plugins).map(([, plugin]) => plugin.name);
        const { colorDepth, availWidth, availHeight } = window.screen;
        const timezoneOffset = new Date().getTimezoneOffset();
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const touchSupport = 'ontouchstart' in window;
        const canvas = (() => {
            try {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                ctx.textBaseline = 'top';
                ctx.font = "14px 'Arial'";
                ctx.textBaseline = 'alphabetic';
                ctx.fillStyle = '#f60';
                ctx.fillRect(125, 1, 62, 20);
                ctx.fillStyle = '#069';
                ctx.fillText("abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ`~1!2@3#4$5%6^7&8*9(0)-_=+[{]}|;:',<.>/?", 2, 15);
                ctx.fillStyle = 'rgba(102, 204, 0, 0.7)';
                ctx.fillText("abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ`~1!2@3#4$5%6^7&8*9(0)-_=+[{]}|;:',<.>/?", 4, 17);

                const result = canvas.toDataURL();
                return result;
            }
            catch (error) {
                return error;
            }
        })();
        var ssdout = ssdeep.digest(Object.values({
            userAgent,
            language,
            languages,
            platform,
            hardwareConcurrency,
            deviceMemory,
            plugins,
            colorDepth,
            IPs: Object.values(IPs),
            availWidth,
            availHeight,
            timezoneOffset,
            timezone,
            touchSupport,
            canvas
        }).join(","))
        console.log({
            userAgent,
            language,
            languages,
            platform,
            hardwareConcurrency,
            deviceMemory,
            plugins,
            colorDepth,
            availWidth,
            availHeight,
            timezoneOffset,
            timezone,
            touchSupport,
            canvas
        })
        var sim = ssdeep.similarity("192:Db1CLluMRRrOn3LHKqhKTLIDgsr8yKo9FKia6UwF3yvf2b/yYxMUec4d+iVkec4d+iVkec4d+3:X1CLjRrO3LqrT8DX8y/7GwF3yn2txOrxrxc", "192:Zb1CLluMRRrOn3LHKqhKTLIDgsr8yKo9FKia6UwF3yvf2b/yYxMUec4d+iVkec4d+iVkec4d+3:Z1CLjRrO3LqrT8DX8y/7GwF3yn2txOrxrxc")
        console.log("Similarity of browser instance is ", sim)
        var ual = parser(userAgent);
        console.log(ual)
        this.setState({
            ua: `${ual.device.vendor} ${ual.device.model}`
        })
        await axios.post("http://localhost:7000/ping", {
            id: uuidv4(),
            p: {
                cookie,
                IPs,
                ssdeep:ssdout,
                extraInfo: {
                    platform,
                    userAgent,
                    language,
                    languages,
                    device: ual.device.vendor && ual.device.model ? `${ual.device.vendor} ${ual.device.model}` : null
                },
            },
            ts: new Date(),
            originInfo: {
                origin: window.location.origin,
                pathname: window.location.pathname,
                hash: window.location.hash
            }
        })
    }
    collect() {

    }
    render() {
        return (<div>
            {/*this.state.ssdeep*/}
        </div>);
    }
}

export default LisaCollector;