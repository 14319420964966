import logo from './logo.svg';
import './App.css';
import {
  HashRouter as Router,
  Switch,
  Route,
  useParams
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import BlogPreview from './BlogPreview';
import BlogRenderer from './views/BlogRenderer'
import LisaCollector from './LisaCollector'
import NotFound from './views/NotFound'

function App() {
  return (
    <div className="App">
      <LisaCollector/>
      <div className="container">
        <hr />
        <center>
          <h1 style={{ fontFamily: 'SCRIPTIN', fontSize: '4.5em' }}>Shalala</h1>
          <h2 className="site-description">
            {/*Where I talk about life, tech, philosophy, and anything else I can think of.*/}
            Always forward, never back.
          </h2>
          <h2 className="site-description">
            Existence is terrifying
          </h2>
        </center>
        <br />
        <hr width="100px" />
        <nav className="navbar navbar-default">
          <div className="container-fluid">
            <center style={{ marginRight: "auto", marginLeft: "auto" }}>
              <ul className="nav navbar-nav" style={{ flexDirection: "row" }}>
                <li><a href="#/">Blog</a></li>
                <li><a href="#/gallary">Gallary</a></li>
                <li><a href="#/about">About</a></li>
              </ul>
            </center>
          </div>
        </nav>
        <Router>
          <Switch>
            <Route exact path="/">
              <BlogPreview/>
            </Route>
            <Route exact path="/gallary">
              <div style={{textAlign:"center", fontSize: "1.35em", fontFamily: 'Roboto'}}>
                Someday this will exist
              </div>
            </Route>
            <Route path="/about">
              <div style={{textAlign:"start", fontSize: "1.25em"}}>
                Completely self made website. And well, what's the point? 
                To post the shit most people don't want to (or shouldn't) see  while still allowing it to be "public." 
                So congrats that you're even reading this!
                Just kind of like tumblr, maybe? idk tbh, I've never been on that site.
                <hr/>
                Also one important thing to mention: I am trying out this "red/black" color scheme, I like it, while also hating it. 
                Likely going to change it in the future. For now, this will be the standard for the site. 
                If you are inclined with colors schemas do let me know where and how it can be improved.

              </div>
          </Route>
          <Route path="/blog/:permlink" component={BlogRenderer}/>
          <Route>
            <NotFound/>
          </Route>
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;
