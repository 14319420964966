import React, { Component } from 'react';
import kitsune from '../resources/kitsune.png'
import './NotFound.css'
class NotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( <div>
            <center>
                <h3>Wow.. This is embarrassing, the page you're looking for does not exist.</h3>
                <br/>
                <img style={{height: "20em", width: "20m"}} src={kitsune}/>
            </center>
        </div> );
    }
}
 
export default NotFound;