import React, { Component } from 'react';
import NotFound from './NotFound';
import ReactMarkdown from 'react-markdown';
import utils from '../utils'
import DateTime from 'date-and-time'
import {Button} from 'react-bootstrap'

class BlogRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            post: {
                
            }
        }
    }
    async componentDidMount() {
        var posts = await utils.getPosts()
        var permlink = this.props.match.params.permlink
        console.log(permlink, posts)
        this.setState({
            post: posts[permlink] || null
        })
    }
    render() { 
        console.log(this.state.post)
        if(this.state.post === null) {
            return (<NotFound/>);
        }
        const pattern = DateTime.compile('MMMM D, YYYY at h:mmA');
        return (<div style={{textAlign:"left", lineHeight:2, fontSize: "1rem"}}>
            <header style={{float:"right"}}>
                <div style={{border:"black 1px", borderStyle: "solid", borderRadius: "5px", padding:"10px"}}>
                    Posted on {DateTime.format(new Date(this.state.post.creation_time), pattern)}
                    <br/>
                    Updated on {DateTime.format(new Date(this.state.post.modification_time), pattern)}
                    <br/>
                    <Button style={{display:"none"}}>Button</Button>

                </div>
            </header>
            <ReactMarkdown className="fear">
                {this.state.post.content}
            </ReactMarkdown>
            <hr/>
            <footer>
                
            </footer>
        </div>);
    }
}
 
export default BlogRenderer;