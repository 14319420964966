import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import DateTime from 'date-and-time'
import preview from './resources/IMG_3600.JPG'
import utils from './utils'
class BlogPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {posts: []}
    }
    componentDidMount() {
        utils.getPosts().then(out => {
            this.setState({
                posts: Object.values(out)
            })
        })
    }
    render() {
        return (<div>
            {
                this.state.posts.map(post => {
                const pattern = DateTime.compile('MMMM D, YYYY at h:mmA');
                return (<a href={`#/blog/${post.permlink}`} style={{textDecoration: 'none'}} ><Row key={post.permlink} className="blog-preview" style={{border:"black 1px", borderStyle: "solid", borderRadius: "5px", padding:"5px", marginRight: '0px', marginLeft: '0px'}}>
                <Col xs={2} className="blog-thumbnail">
                    <img src={preview} style={{ width: "inherit", borderRadius: '5px' }} />
                </Col>
                <Col>
                    <Row>
                        <a href={`#/blog/${post.permlink}`}>
                            <h3 style={{ color: "#ff7200", float: "left" }}>{post.title}</h3>
                        </a>
                    </Row>
                    <Row>
                        <h5 style={{ float: "left", textAlign: "start", fontSize: "1.15rem", color: 'crimson' }}>
                            {post.summary}
                        </h5>
                    </Row>
                    <Row>
                        {post.tags.map(tag => (
                            <div style={{background:"gray", borderRadius:"25px"}}>
                                <strong style={{color:"white", padding:"10px"}}>{tag}</strong>
                            </div>
                        ))}
                        <div style={{float:"right", color: 'crimson'}}>
                            {DateTime.format(new Date(post.creation_time), pattern)}
                        </div>
                    </Row>
                </Col>
                </Row></a>)})
            }
            
        </div>);
    }
}

export default BlogPreview;